import React from 'react'

type Props = {}

const Loader = (props: Props) => {
    return (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-80 flex items-center justify-center z-50">
            <span className="animate-spin border-4 border-primary border-l-transparent rounded-full w-10 h-10"></span>
        </div>
    )
}

export default Loader