import { createBrowserRouter, RouteObject } from 'react-router-dom';
import { roleGuard } from './RoleGuard';
import Login from '../pages/auth/Login';
import PrivateRoutes from './PrivateRoute';
import Index from '../pages/Index';
import ProfileView from '../pages/profile/ProfileView';
import ScheduleView from '../pages/schedule/ScheduleView';
import Register from '../pages/auth/Register';
import RegisterNew from '../pages/auth/RegisterNew';
import RegisterExist from '../pages/auth/RegisterExist';
import AttendanceView from '../pages/schedule/attendance/AttendanceView';
import ResetPassword from '../pages/auth/ResetPassword';
import InboxList from '../pages/inbox/InboxList';
import InboxView from '../pages/inbox/InboxView';
// import { academyRoutes } from './AcademyRoute';

const routes: RouteObject[] = [
    { path: "login", element: <Login /> },
    { path: "register", element: <Register /> },
    { path: "register-new", element: <RegisterNew /> },
    { path: "register-exist", element: <RegisterExist /> },
    { path: "reset-password", element: <ResetPassword/> },
    {
        path: "/",
        loader: roleGuard,
    },
    // ...academyRoutes
    {
        path: "/",
        element: <PrivateRoutes />,
        children: [
            { index: true, element: <Index />},
            { path: "profile",
                children: [
                    { index: true, element: <ProfileView /> },
                ],
            },
            { path: "schedule",
                children: [
                    { index: true, element: <ScheduleView /> },
                    { path: ":id/:date", element: <AttendanceView /> },
                ],
            },
            { path: "inbox",
                children: [
                    { index: true, element: <InboxList /> },
                    { path: ":id", element: <InboxView /> },
                ],
            },
        ],
    },
];

const router = createBrowserRouter(routes)

export default router;
