import React, { useEffect, useState } from 'react'
import IconUser from '../../component/icon/IconUser';
import api from '../../api/api';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../component/layout/Loader';
import { t } from 'i18next';

type Props = {}

const InboxList = (props: Props) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [notiifications, setNotifications] = useState<any>([])

    useEffect(() => {
        setLoading(true)
        api.get(`${process.env.REACT_APP_API_BASE_URL}/notification`).then(res => {
        setLoading(false)
            console.log("notification", res.data.data)
            setNotifications(res.data.data)
        }).catch(err => {
            setLoading(false)
            console.log("err cart", err)
        })
    }, [])


    function formatDate(dateString: string) {
        const date = new Date(dateString)
        
        const day = date.getUTCDate()
        const month = date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' })
        const year = date.getUTCFullYear()
        const hours = date.getUTCHours()
        const minutes = date.getUTCMinutes().toString().padStart(2, '0')
        const period = hours >= 12 ? 'PM' : 'AM'
        const formattedHours = hours % 12 || 12 // Convert to 12-hour format

        const formattedDate = `${month} ${day}, ${year}, ${formattedHours}:${minutes} ${period}`

        return formattedDate
    }
    
    return (
        <div>
            {loading && <Loader/>}
            <div className="flex items-center justify-between flex-wrap gap-4">
                <h2 className="text-xl">{t("inbox.notifications")}</h2>
            </div>
            <div className="mt-5 panel p-0 border-0 overflow-hidden">
                <div className="table-responsive">
                    <table className="table-striped table-hover">
                        <thead>
                            <tr>
                                <th>{t("inbox.sender")}</th>
                                <th>{t("inbox.title")}</th>
                                <th>{t("date")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notiifications.map((notification: any) => {
                                return (
                                    <tr key={notification.id} onClick={()=>navigate(`/inbox/${notification.id}`)} className={`cursor-pointer ${notification.seen ? "" : "font-bold"}`}>
                                        <td>
                                            <div className="flex items-center w-max">
                                                {notification.sender_pic ? (
                                                    <div className="w-max">
                                                        <img src={`${notification.sender_pic}`} className="h-8 w-8 rounded-full object-cover ltr:mr-2 rtl:ml-2" alt="avatar" />
                                                    </div>
                                                ) : (
                                                    <div className="w-max">
                                                        <img src={`/assets/images/koku.png`} className="h-8 w-8 rounded-full object-cover ltr:mr-2 rtl:ml-2" alt="avatar" />
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>{notification.title || "-"}</td>
                                        <td>{notification.sent_at ? formatDate(notification.sent_at) : "-"}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default InboxList