import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import IconMail from '../../component/icon/IconMail'
import axios from 'axios'
import BlankLayout from '../../component/layout/BlankLayout'
import { toast } from 'react-toastify'

type Props = {}

const Register = (props: Props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState("")
    const [otp, setOtp] = useState("")
    const [showOtp, setShowOtp] = useState(false)
    const [loading, setLoading] = useState(false)

    const generateOtp = async () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/generate-otp`, {
            email: email,
        }).then( res => {
            setLoading(false)
            console.log("res", res)
            // If user not exist, would be new user. So we will be also need to ask user to fill in all the info that we needed
            // show otp column
            setShowOtp(true)
        }).catch( err => {
            setLoading(false)
            console.log("err", err)
            toast.error("Error sending OTP")
        })
    }

    const checkExist = async () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/exist`, {
            "email": email,
            "otp": parseInt(otp)
        }, {headers: {'Content-Type': 'application/json', "Accept":"application/json"}, withCredentials: true }).then( res => {
            setLoading(false)
            console.log("res check exist", res)
            if (res.data.is_exist === false) {
                navigate(`/register-new?email=${email}`)
            } else {
                navigate(`/register-exist?email=${email}`)
            }
        }).catch( err => {
            setLoading(false)
            console.log("err", err.response)
            toast.error("Error validating user account")
        })
    }



    return (
        <BlankLayout>
            <div className="relative flex min-h-screen items-center justify-center bg-cover bg-center bg-no-repeat px-6 py-10 dark:bg-[#060818] sm:px-16">
                <div className="relative w-full max-w-[870px] rounded-md bg-[linear-gradient(45deg,#fff9f9_0%,rgba(255,255,255,0)_25%,rgba(255,255,255,0)_75%,_#fff9f9_100%)] p-2 dark:bg-[linear-gradient(52.22deg,#0E1726_0%,rgba(14,23,38,0)_18.66%,rgba(14,23,38,0)_51.04%,rgba(14,23,38,0)_80.07%,#0E1726_100%)]">
                    <div className="relative flex flex-col justify-center rounded-md backdrop-blur-lg dark:bg-black/50 px-6 lg:min-h-[758px] py-20">
                        <div className="mx-auto w-full max-w-[440px]">
                            <div className="mb-10">
                                <img className="w-20" src="/assets/images/koku.png" alt="logo"/>
                                <h1 className="text-3xl font-extrabold uppercase !leading-snug text-primary md:text-4xl">Sign Up</h1>
                                <p className="text-base font-bold leading-normal text-white-dark">Enter your email and password to register</p>
                            </div>
                            <div className="space-y-5 dark:text-white">
                                <div>
                                    <label>Email</label>
                                    <div className="relative text-white-dark">
                                        <input type="email" placeholder="Enter Email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                {showOtp && (
                                <div>
                                    <label>OTP</label>
                                    <div className="relative text-white-dark">
                                        <input type="text" placeholder="Enter OTP" value={otp} onChange={(e)=>setOtp(e.target.value)} className="form-input ps-10 placeholder:text-white-dark" />
                                        <span className="absolute start-4 top-1/2 -translate-y-1/2">
                                            <IconMail fill={true} />
                                        </span>
                                    </div>
                                </div>
                                )}
                                <button type="button" className="btn btn-primary rounded-3xl !mt-6 w-full border-0 uppercase shadow-[0_10px_20px_-10px_rgba(67,97,238,0.44)]" onClick={()=> {
                                    if (showOtp) {
                                        return checkExist()
                                    } else {
                                        return generateOtp()
                                    }
                                }}>
                                    {loading ? (<span className="animate-spin border-2 border-white dark:border-white !border-l-transparent rounded-full w-5 h-5 inline-flex"></span>) : (showOtp ? "VERIFY OTP" : "SEND OTP")}
                                </button>
                            </div>
                            <div className="relative my-7 text-center md:mb-9">
                                <span className="absolute inset-x-0 top-1/2 h-px w-full -translate-y-1/2 bg-white-light dark:bg-white-dark"></span>
                                <span className="relative bg-white px-2 font-bold uppercase text-white-dark dark:bg-dark dark:text-white-light">or</span>
                            </div>
                            <div className="text-center dark:text-white">
                                Already have an account ?&nbsp;
                                <Link to="/login" className="uppercase text-primary underline transition hover:text-black dark:hover:text-white">
                                    SIGN IN
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BlankLayout>
    )
}

export default Register