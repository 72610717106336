import React, { useEffect, useState } from 'react'
import IconUser from '../../component/icon/IconUser'
import { useParams } from 'react-router-dom'
import api from '../../api/api'
import Loader from '../../component/layout/Loader'

type Props = {}

const InboxView = (props: Props) => {
    const [notification, setNotification] = useState<any>({})
    const [loading, setLoading] = useState(false)
    let { id } = useParams()

    useEffect(() => {
        setLoading(true)
        api.get(`${process.env.REACT_APP_API_BASE_URL}/notification/${id}`).then(res => {
            console.log("notification", res.data.data)
            setNotification(res.data.data)
        }).catch(err => {
            console.log("err notification", err)
        }).finally(() => {
            setLoading(false)
            readNotification(id)
        })
    }, [])

    function readNotification(notificationId:any) {
        api.post(`${process.env.REACT_APP_API_BASE_URL}/notification/seen/${notificationId}`).then(res => {
            console.log("read", res.data)
        }).catch(err => {
            console.log("err cart", err)
        })
    }


    function formatDate(dateString: string) {
        const date = new Date(dateString)
        
        const day = date.getUTCDate()
        const month = date.toLocaleString('en-US', { month: 'long', timeZone: 'UTC' })
        const year = date.getUTCFullYear()
        const hours = date.getUTCHours()
        const minutes = date.getUTCMinutes().toString().padStart(2, '0')
        const period = hours >= 12 ? 'PM' : 'AM'
        const formattedHours = hours % 12 || 12 // Convert to 12-hour format

        const formattedDate = `${month} ${day}, ${year}, ${formattedHours}:${minutes} ${period}`

        return formattedDate
    }

    return (
        <>
        {loading && <Loader/>}
        <div className="panel">
            <div className="mb-5 grid grid-cols-5">
                <div className='col-span-1'>
                    <div className="w-max">
                        <img src={notification.sender_pic} className="h-8 w-8 rounded-full object-cover ltr:mr-2 rtl:ml-2" alt="avatar" />
                    </div>
                </div>
                <h5 className="col-span-4 dark:text-white-light grid grid-rows-2">
                    <div className='text-xl font-medium '>{notification.sender_name || "-"}</div>
                    <div>{notification.sent_at ? formatDate(notification.sent_at) : "-"}</div>
                </h5>
            </div>
            <div className="mb-5 font-medium text-xl">
                {notification.title || "-"}
            </div>
            <div className="">
                {notification.description || "-"}
            </div>
        </div>
        </>
    )
}

export default InboxView