import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Request Interceptor
api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const accessToken = localStorage.getItem("token")
    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    // You can also check if the token needs refreshing here
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    // Any status code within the range of 2xx causes this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // Any status codes outside the range of 2xx cause this function to trigger
    // Do something with response error

    // Example of handling a 401 Unauthorized response
    if (error.response.status === 401 && !originalRequest._retry) {
        localStorage.removeItem("token")
        localStorage.removeItem("refreshToken")
      window.location.href = "/login";
      // originalRequest._retry = true;
      // try {
      //   const refreshToken = AppCache.getRefreshToken();
      //   const response = await api.post("/api/authentication/token/refresh", {
      //     refresh: refreshToken,
      //   });

      //   const token = response.data.access;

      //   AppCache.setAccessToken(token);

      //   originalRequest.headers.Authorization = `Bearer ${token}`;
      //   return axios(originalRequest);
      // } catch (error) {
      //   AppCache.removeAccessToken();
      //   AppCache.removeRefreshToken();
      //   return redirect("/login");
      // }
    }

    return Promise.reject(error);
  }
);

export default api;
