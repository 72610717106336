import { combineReducers, configureStore } from '@reduxjs/toolkit';
import themeConfigSlice from './themeConfigSlice';
import attendanceSlice from './slice/AttendanceSlice';

const rootReducer = combineReducers({
    themeConfig: themeConfigSlice,
    attendance: attendanceSlice,
})

export default configureStore({
    reducer: rootReducer,
})

export type IRootState = ReturnType<typeof rootReducer>
