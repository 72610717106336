import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../component/layout/Loader'
import { toast } from 'react-toastify'
import IconCaretsDown from '../../component/icon/IconCaretsDown'
import AnimateHeight from 'react-animate-height'
import Dropdown from '../../component/Dropdown'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import api from '../../api/api'


const ProfileView = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [coachData, setCoachData] = useState({
        fullName:"",
        email: "",
        phoneNumber: "",
        city: "",
        dob: new Date(),
        verified: false,
        ic: "",
        assigned_info: null,
        profilePic: "",
        bank: "0",
        bankName: "",
        bankAccountNumber: "",
        bankAccountName: "",
        profile_pic: ""
    })
    const [bankList, setBankList] = useState([])

    useEffect(() => {
        getCoachDetail()
        getBanks()
    }, [])
    
    
    const getBanks = async () => {
        api.get(`${process.env.REACT_APP_API_BASE_URL}/bank`).then( res => {
            console.log("bank 21", res.data.data)
            setBankList(res.data.data)
        }).catch( err => {
            console.log("err", err)
            toast.error(t("server_error"))
        })
    }

    const getCoachDetail = async () => {
        setLoading(true)
        api.get(`${process.env.REACT_APP_API_BASE_URL}/user/profile`).then( res => {
            console.log("res 21", res.data.data)
            setCoachData({
                fullName: res.data.data.name,
                email: res.data.data.email,
                city: res.data.data.city,
                phoneNumber: res.data.data.phone,
                dob: res.data.data.birthday || new Date(),
                verified: res.data.data.is_approved,
                ic: res.data.data.ic_number,
                assigned_info: res.data.data.assigned_info,
                profilePic: res.data.data.profile_pic,
                bank: res.data.data.bank || "0",
                bankName: res.data.data.bank_name,
                bankAccountNumber: res.data.data.bank_account_number,
                bankAccountName: res.data.data.bank_account_name,
                profile_pic: res.data.data.profile_pic,
            })

        }).catch( err => {
            console.log("err", err)
            toast.error(t("server_error"))
        }).finally(()=>{
            setLoading(false)
        })
    }
    
    const updateCoach = async () => {
        if (coachData.bank == "0") {
            return toast.warn("Please select a bank")
        }

        setLoading(true)
        let payload :any = {
            "name": coachData.fullName,
            "email": coachData.email,
            "city": coachData.city,
            "phone": coachData.phoneNumber,
            "birthday": coachData.dob,
            "ic_number": coachData.ic,
            "assigned_info": coachData.assigned_info,
            "bank": coachData.bank,
            "bank_account_number": coachData.bankAccountNumber,
            "bank_account_name": coachData.bankAccountName
        }

        
        // Check if profile_pic is Base64 data
        if (coachData.profile_pic.startsWith("data:")) {
            payload["profile_pic"] = coachData.profile_pic
        }

        console.log("payload", payload)
        api.put(`${process.env.REACT_APP_API_BASE_URL}/user/profile`, payload).then( res => {
            console.log("res", res.data)
            toast.success(t("update_success"))

        }).catch( err => {
            console.log("err", err)
            if (err.response && err.response.data && err.response.data.errors) {
                const errors = err.response.data.errors
                Object.keys(errors).forEach(key => {
                    errors[key].forEach((message:string) => {
                        toast.error(`${key} : ${message}`)
                    })
                })
            } else {
                toast.error(t("server_error"))
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const [active, setActive] = useState<string>('0');
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const togglePara = (value: string) => {
        setActive((oldValue) => {
            return oldValue === value ? '' : value;
        });
    }
    
    function handleChangePassword() {
        if (newPassword !== confirmPassword) return toast.error(t("profile.password_not_match"))

        setLoading(true)
        api.post(`${process.env.REACT_APP_API_BASE_URL}/user/change-password`, {
            "password": newPassword,
            "confirm_password": confirmPassword
        }, {
            headers: {
                "Authorization": localStorage.getItem("token"),
                "Content-Type": "application/json"
            }
        }).then(res => {
            setNewPassword("")
            setConfirmPassword("")
            toast.success(t("profile.success_change_password"))
            
        }).catch(err =>{
            console.log("err", err)
            toast.error(t("server_error"))

        }).finally(() => {
            setLoading(false)
        })
    }

    const handleImageChange = (e:any) => {
        const file = e.target.files[0];
        if (file) {
            const validExtensions = ["image/jpeg", "image/png", "image/jpg"]
            if (!validExtensions.includes(file.type)) return toast.error("Only JPG and PNG images are allowed.")

            const maxFileSize = 3 * 1024 * 1024 // 1 MB in bytes
            if (file.size > maxFileSize) return toast.error("Image size should not exceed 3 MB")
            
            const reader = new FileReader();
            reader.onloadend = () => {
                setCoachData((prevData:any) => ({
                    ...prevData,
                    profile_pic: reader.result,
                }));
            };
            reader.readAsDataURL(file)
        }
    }

    return (
        <div>
            {loading && <Loader/>}
            <ul className="flex space-x-2 rtl:space-x-reverse">
                <li>
                    <span>{t('profile.coach_settings')}</span>
                </li>
            </ul>
            <div className="pt-5">
                <form className="border border-[#ebedf2] dark:border-[#191e3a] rounded-md p-4 mb-5 bg-white dark:bg-black">
                    <div className="flex flex-col sm:flex-row">
                        {/* <div className="ltr:sm:mr-4 rtl:sm:ml-4 w-full sm:w-2/12 mb-5">
                            <img src="/assets/dummy/profile-31.jpeg" alt="img" className="w-20 h-20 md:w-32 md:h-32 rounded-full object-cover mx-auto" />
                        </div> */}
                        <div className="relative w-20 h-20 md:w-32 md:h-32 mx-auto mb-5 sm:mr-4" onClick={() => document.getElementById("fileInput")!.click()} >
                            <img src={coachData.profile_pic || "/assets/dummy/profile-31.jpeg"} alt="Profile" className="w-full h-full rounded-full object-cover" />
                            <input id="fileInput" type="file" accept=".jpg,.jpeg,.png" className="hidden" onChange={handleImageChange} />
                        </div>
                        <div className="flex-1 grid grid-cols-1 sm:grid-cols-2 gap-5">
                            <div>
                                <label>{t('profile.full_name')}</label>
                                <input value={coachData.fullName} onChange={(e)=>setCoachData( prev => ({ ...prev, fullName: e.target.value}))} type="text" placeholder={t('profile.identification_number')} className="form-input" />
                            </div>
                            <div>
                                <label>{t('profile.email')}</label>
                                <input value={coachData.email} onChange={(e)=>setCoachData( prev => ({ ...prev, email: e.target.value}))} type="email" placeholder={t('profile.email')} className="form-input" />
                            </div>
                            <div>
                                <label>{t('profile.identification_number')}</label>
                                <input value={coachData.ic} onChange={(e)=>setCoachData( prev => ({ ...prev, ic: e.target.value}))} type="text" placeholder="12345678" className="form-input" />
                            </div>
                            <div>
                                <label>{t('profile.phone')}</label>
                                <input value={coachData.phoneNumber} onChange={(e)=>setCoachData( prev => ({ ...prev, phoneNumber: e.target.value}))} type="number" placeholder="+1 (530) 555-12121" className="form-input" />
                            </div>
                            <div>
                                <label>{t('profile.city')}</label>
                                <input value={coachData.city} onChange={(e)=>setCoachData( prev => ({ ...prev, city: e.target.value}))} type="text" placeholder={t('profile.city')} className="form-input" />
                            </div>
                            <div>
                                <label>{t('profile.date_of_birth')}</label>
                                <DatePicker
                                    onChange={(date:any)=>setCoachData( prev => ({ ...prev, dob: date}))}
                                    value={new Date(coachData.dob)}
                                    format="yyyy-MM-dd"
                                    className="form-input"
                                    clearIcon
                                    calendarIcon
                                />
                            </div>
                            <div></div>
                            <div>
                                <label className="inline-flex cursor-pointer">
                                    <input disabled type="checkbox" checked={coachData.verified || false} className="form-checkbox" />
                                    <span className="text-white-dark relative checked:bg-none">{t('profile.verified')}  </span>
                                </label>
                            </div>
                            
                            <div>
                                <label>{t('profile.bank_name')}</label>
                                <select value={coachData.bank} className="form-select"  onChange={(e)=>setCoachData( prev => ({ ...prev, bank: e.target.value}))}>
                                    <option value="0" disabled>-- {t("profile.select_bank")} --</option>
                                    {bankList.map((bank:any, index:number) => {
                                        return (
                                            <option key={index} value={bank.id}>{bank.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            
                            <div>
                                <label>{t('profile.bank_acc_number')}</label>
                                <input value={coachData.bankAccountNumber || ""} onChange={(e)=>setCoachData( prev => ({ ...prev, bankAccountNumber: e.target.value}))} type="text" placeholder={t('profile.bank_acc_number')} className="form-input" />
                            </div>
                            
                            <div>
                                <label>{t('profile.bank_acc_name')}</label>
                                <input value={coachData.bankAccountName || ""} onChange={(e)=>setCoachData( prev => ({ ...prev, bankAccountName: e.target.value}))} type="text" placeholder={t('profile.bank_acc_name')} className="form-input" />
                            </div>

                            <div className="sm:col-span-2 flex mt-3 gap-2">
                                <button type="button" onClick={()=>{navigate("/")}} className="btn btn-secondary">
                                {t('back')}  
                                </button>
                                <button type="button" onClick={()=>{updateCoach()}} className="btn btn-primary">
                                {t('update')}  
                                </button>
                            </div>
                        </div>



                    </div>
                <div className="mt-5">
                            <div className="border border-[#d3d3d3] rounded dark:border-[#1b2e4b]">
                                <button type="button" className={`p-4 w-full flex items-center dark:bg-[#1b2e4b]`} onClick={() => togglePara('1')} >
                                    {t('profile.change_password')}  
                                    <div className={`ltr:ml-auto rtl:mr-auto ${active === '1' ? 'rotate-180' : ''}`}>
                                        <IconCaretsDown />
                                    </div>
                                </button>
                                <div>
                                    <AnimateHeight duration={300} height={active === '1' ? 'auto' : 0}>
                                        <div className="space-y-2 p-4 text-[13px] border-t border-[#d3d3d3] dark:border-[#1b2e4b]">
                                            <div>
                                                <label>{t('profile.new_password')}</label>
                                                <input type="password" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} className="form-input" />
                                            </div>
                                            <div className='pb-3'>
                                                <label>{t('profile.confirm_new_password')}</label>
                                                <input type="password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} className="form-input" />
                                            </div>
                                            <div >
                                                <button type="button" onClick={()=>{handleChangePassword()}} className="btn btn-primary">
                                                {t('profile.change_password')}
                                                </button>
                                            </div>
                                        </div>
                                    </AnimateHeight>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown mt-5">
                            <Dropdown
                                placement={`bottom-end`}
                                btnClassName="border border-[#d3d3d3] rounded dark:border-[#1b2e4b] p-3  w-full"
                                button={
                                    <>
                                        <div>{t('profile.language')}</div>
                                        <div className='ml-auto'>
                                            <IconCaretsDown className="ltr:ml-1 rtl:mr-1 inline-block" />
                                        </div>
                                    </>
                                }
                            >
                                <ul className=" w-full divide-y ">
                                    <li onClick={(e) => { i18next.changeLanguage("en"); }}>
                                        <button type="button" className="place-content-start">
                                            English
                                        </button>
                                    </li>
                                    <li  onClick={(e) => { i18next.changeLanguage("zh"); }}>
                                        <button type="button" className="place-content-start">
                                            Mandarin
                                        </button>
                                    </li>
                                    <li  onClick={(e) => { i18next.changeLanguage("ms"); }}>
                                        <button type="button" className="place-content-start">
                                            Malay
                                        </button>
                                    </li>
                                </ul>
                            </Dropdown>
                        </div>
                </form>

                
            </div>
        </div>
    )
}

export default ProfileView