import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import { useState, Fragment, useEffect, useRef } from 'react';
import { publicHolidays, schoolHolidays } from '../../util/holidaysData'
import './style/CustomCalendar.css'
import { DataTable } from 'mantine-datatable'
import { useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react';
import IconX from '../../component/icon/IconX';
import IconCamera from '../../component/icon/IconCamera';
import Webcam from 'react-webcam';
import IconRefresh from '../../component/icon/IconRefresh';
import api from '../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { setCapturedImage, setSelectedCourse } from '../../store/slice/AttendanceSlice';
import { IRootState } from '../../store';
import Loader from '../../component/layout/Loader';
import { t } from 'i18next';

type Props = {}

const PAGE_SIZES = [10, 20, 30, 50, 100]

const ScheduleView = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [initialRecords, setInitialRecords] = useState(items)
    const [records, setRecords] = useState(initialRecords)
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZES[0])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selectedCourse = useSelector((state: IRootState) => state.attendance.selectedCourse);

    useEffect(() => {
        setPage(1)
    }, [pageSize])

        
    useEffect(() => {
        const from = (page - 1) * pageSize
        const to = from + pageSize
        setRecords([...initialRecords.slice(from, to)])
    }, [page, pageSize, initialRecords])

    const [showCourseModal, setShowCourseModal] = useState(false)
    const [courses, setCourses] = useState([])
    const [selectedDate, setSelectedDate] = useState("")
    const openCourse = (courses: any = null, date: string) => {
        setCourses(courses)
        setSelectedDate(date)
        setShowCourseModal(true)
    };

    const videoRef = useRef<any>(null);
    const [showCamera, setShowCamera] = useState(false)
        
    const captureImage = () => {
        const imageSrc = videoRef.current.getScreenshot()
        if (imageSrc) {
            dispatch(setCapturedImage(imageSrc))
        }
        setShowCamera(false)
        navigate(`/schedule/${selectedCourse.id}/${selectedDate}`)
    }

    const getCameraStream = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            videoRef.current.srcObject = stream;
        } catch (error) {
            console.error("Error accessing camera:", error);
        }
    }

    useEffect(() => {
        getAttendaceList()
    }, [])
    
    async function getAttendaceList() {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0]
        setLoading(true)
        api.get(`schedule/${formattedDate}`).then(res => {
            setLoading(false)
            console.log("res schedule", res.data.data)
            setInitialRecords(res.data.data)
        }).catch(err => {
            setLoading(false)
            console.log("err", err)
        })
    }


    const [cameraFrontOrBack, setCameraFrontOrBack] = useState("environment")

    return (
        <div>
            {loading && (<Loader/>)}
            <div className="flex items-center justify-between flex-wrap gap-4">
                <h2 className="text-xl">{t('schedule.schedules')}</h2>
            </div>

            <div className="mt-5 panel p-0 border-0 overflow-hidden">
                <div className="table-responsive">
                        <DataTable
                            className="whitespace-nowrap table-hover invoice-table"
                            records={records}
                            columns={[
                            {
                                accessor: 'date',
                                title: t("date"),
                                render: ({ date, courses }) => (
                                    <div onClick={() => openCourse(courses, date)}>
                                        <div className="text-black underline hover:no-underline font-semibold">{`${date}`}</div>
                                    </div>
                                ),
                            },
                            {
                                accessor: 'courses',
                                title: t("courses"),
                                titleClassName:"No of Course",
                                render: ({ date, courses }:any) => (
                                    <div>
                                        <div className="text-black hover:no-underline font-semibold">{`${courses.length}`}</div>
                                    </div>
                                ),
                            },
                        ]}
                        totalRecords={initialRecords.length}
                        recordsPerPage={pageSize}
                        page={page}
                        key={"coach_id"}
                        onPageChange={(p) => setPage(p)}
                        recordsPerPageOptions={PAGE_SIZES}
                        onRecordsPerPageChange={setPageSize}
                        paginationText={({ from, to, totalRecords }) => `Showing  ${from} to ${to} of ${totalRecords} entries`}
                    />
                </div>
            </div>

            <Transition appear show={showCourseModal} as={Fragment} static >
                <Dialog as="div" open={showCourseModal} onClose={() => {
                    setShowCamera(false)
                    setShowCourseModal(false)
                }} className="relative z-[51]">
                    <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-[black]/60" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center px-4 py-8">
                            {showCamera ? (
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-5xl text-black dark:text-white-dark">
                                        <div className="flex justify-between px-6 pt-4">
                                            <div className="">{selectedCourse.name}</div>
                                            <button type="button" onClick={() => {
                                                setShowCamera(false)
                                            }} className="col-span-2 ltr:right-4 rtl:left-4 text-gray-400 hover:text-gray-800 dark:hover:text-gray-600 outline-none" >
                                                <IconX />
                                            </button>
                                        </div>
                                        <div className="mt-4">
                                            <Webcam audio={false} ref={videoRef} screenshotFormat="image/jpeg" width={"100%"} videoConstraints={{ facingMode: { exact: cameraFrontOrBack } }}/>
                                        </div>

                                        <div className="flex gap-8 my-4 items-center justify-center">
                                            <div onClick={()=>{captureImage()}} className="btn btn-sm btn-outline-primary">
                                                <IconCamera />
                                            </div>
                                            <button onClick={()=>{
                                                if (cameraFrontOrBack == "user") {
                                                    setCameraFrontOrBack("environment")
                                                } else {
                                                    setCameraFrontOrBack("user")
                                                }
                                            }} className="btn btn-sm btn-outline-primary">
                                                <IconRefresh />
                                            </button>
                                            
                                        </div>

                                    </Dialog.Panel>
                                </Transition.Child>
                            ) : (
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="panel border-0 p-0 rounded-lg overflow-hidden w-full max-w-5xl text-black dark:text-white-dark">
                                    <button type="button" onClick={() => setShowCourseModal(false)} className="absolute top-4 ltr:right-4 rtl:left-4 text-gray-400 hover:text-gray-800 dark:hover:text-gray-600 outline-none" >
                                        <IconX />
                                    </button>
                                    <div className="text-lg font-medium bg-[#fbfbfb] dark:bg-[#121c2c] ltr:pl-5 rtl:pr-5 py-3 ltr:pr-[50px] rtl:pl-[50px]">
                                        {selectedDate}
                                    </div>
                                    <div className="p-5 table-responsive">
                                        <table className="table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{ minWidth: "100px" }}></th>
                                                    <th style={{ minWidth: "200px" }}>{t('course')} & {t('branch')}</th>
                                                    <th style={{ minWidth: "200px" }}>{t('venue')} & {t('time')}</th>
                                                    <th className="!text-center">{t('schedule.take_attendance')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {courses.map((course:any, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {course.images && course.images.length > 0 ? (
                                                                <img src={course.images[0]} className="rounded-full object-cover" style={{ width: "50px", height: "50px" }} />
                                                            ):(
                                                                <img src={`/assets/images/koku-logo.png`} className="rounded-full object-cover" style={{ width: "50px", height: "50px" }} />
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className='font-semibold'>{course.name}</div>
                                                            <div>{course.branch_name}</div>
                                                        </td>
                                                        <td>
                                                            {course.type=="course" && (<div>{course.classroom}</div>)}
                                                            {course.type=="event" && (<div>{course.location}</div>)}
                                                            {course.type=="course" && (<div>{course.start_time} - {course.end_time}</div>)}
                                                        </td>
                                                        <td>
                                                            <div className="flex gap-4 items-center justify-center">
                                                                <div  onClick={()=>{
                                                                    dispatch(setSelectedCourse({
                                                                        name: course.name,
                                                                        id: course.id
                                                                    }))
                                                                    getCameraStream()
                                                                    setShowCamera(true)
                                                                }} className="btn btn-sm btn-outline-primary">
                                                                    <IconCamera />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                        <div className="flex justify-end items-center mt-8">
                                            <button type="button" className="btn btn-outline-danger" onClick={() => setShowCourseModal(false)}>
                                                {t('close')}
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            )}
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}

export default ScheduleView