import { Navigate, useLocation } from "react-router-dom";
import DefaultLayout from "../component/layout/DefaultLayout";

const PrivateRoutes = () => {
    const location = useLocation();
    const authLogin = localStorage.getItem("token")
    // const role = localStorage.getItem("role") as "coach" | null

    if (authLogin === null) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    // if (role === "school" && !location.pathname.startsWith('/school')) {
    //     return <Navigate to="/school" />;
    // }

    // if (role === "academy" && !location.pathname.startsWith('/academy')) {
    //     return <Navigate to="/academy" />;
    // }


    return <DefaultLayout />
}

export default PrivateRoutes;