import { createSlice } from '@reduxjs/toolkit'

export const attendanceSlice = createSlice({
    name: 'attendance',
    initialState: {
        capturedImage: null,
        selectedCourse: {
            id: null,
            name: null
        }
    },
    reducers: {
        setSelectedCourse(state, action) {
            state.selectedCourse = action.payload
        },
        setCapturedImage(state, action) {
            state.capturedImage = action.payload
        },
        clearCapturedImage(state) {
            state.capturedImage = null
        },
    },
})

export const { setCapturedImage, clearCapturedImage, setSelectedCourse } = attendanceSlice.actions
export default attendanceSlice.reducer